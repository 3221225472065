







































































import Vue from 'vue';
import { Route } from 'vue-router';
import { auth0NavGuard } from '@/assets/plugins/auth0Client3';
import { ClickAway } from '@/assets/mixins';

import { User } from '@lordly/models2/interfaces/models/User';
import { MutationPayload, ActionPayload } from '@/interfaces';
import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';
import { LandlordState } from '@/store/landlord/state';
import { RootStore } from '@/store';
import { Blob } from '@lordly/models2/interfaces';

export default Vue.extend({
  name: 'Landlord-Layout',
  data () {
    return {
      showPopupMenu: false,
      showTutorialPopupMenu: false,
      routes: [
        {
          label: 'Reports',
          path: '/landlord/reports',
          icon: 'mdi-chart-line-variant',
        },
        {
          label: 'Portfolio',
          path: '/landlord/portfolio',
          icon: 'mdi-home',
        },
        {
          label: 'Share Portfolio',
          path: '/landlord/share',
          icon: 'mdi-share-variant',
        },
        {
          label: 'Enquiry',
          path: '/landlord/enquiry',
          icon: 'mdi-email',
          activeIcon: 'mdi-email-open',
        },
        {
          label: 'Ideas',
          path: '/landlord/idea',
          icon: 'mdi-lightbulb',
          activeIcon: 'mdi-lightbulb-on',
          activeColor: 'yellow',
        },
        {
          label: 'Settings',
          path: '/landlord/settings',
          icon: 'mdi-settings',
        },
      ],
      currentRoute: 1,
      loadingMyInfo: false,
    };
  },
  computed: {
    profileImage (): string {
      const user: LandlordState['user'] = (this.$store.state.landlord as LandlordState).user;
      // Set default URL
      let imageUrl: string = (this.$store.state as RootStore).avatar;
      // Determine if image
      if (user.image && user.image.length > 0) {
        const image: Blob = user.image[0];
        imageUrl = image.url;
      }
      // Determine if override image in place
      if (user.overrideImage) {
        imageUrl = user.overrideImage;
      }
      // Return image
      return imageUrl;
    },
  },
  async created () {
    // Set Bearer token
    const jwt: string = this.$auth0.jwtToken;
    if (jwt) {
      this.$gql.SetBearerToken(jwt, this);
      this.$blob.SetBearerToken(jwt);

      this.getMyInfo();

      // One off execution, get the portfolio
      this.$vuex.MutateStore(this, 'landlord', 'portfolio.loading', true);
      await this.$store.dispatch('landlord/actionGetPortfolio', { component: this } as ActionPayload);
      this.$vuex.MutateStore(this, 'landlord', 'portfolio.loading', false);

      // Determine route and set current route
      for (const idx in this.routes) {
        if (this.routes[idx]) {
          const route: LayoutSubRoute = this.routes[idx];
          if (route.path.indexOf(this.$route.path) > -1) {
            this.currentRoute = parseInt(idx);
            break;
          }
        }
      }

      // Initialise the add property form - (Not initalised due to SonarQube duplication error, this fixes it)
      this.$store.commit('landlord/MutateResetForm');
    }
  },
  // Lifecycle Hooks
  beforeDestroy () {
    this.$gql.ClearBearerToken();
    this.$blob.ClearBearerToken();
  },
  // Router Lifecycles
  beforeRouteEnter (to, from, next) {
    // "this" is not avaliable for this particular router lifecycle event
    // Performace hit should be minor given that this is only fired ONCE
    auth0NavGuard(to, from, next);
  },
  async beforeRouteUpdate (to, from, next) {
    // Check if current session is valid before route update
    if (await this.$auth0.isAuthenticated) {
      next();
      this.setCurrentRoute(to);
    } else {
      next('/');
    }
  },
  // Methods
  methods: {
    togglePopupMenu (showTutorialOptions: boolean) {
      // If open (Yes its crazy fucking logic...w/e), improve it pls
      if (this.showPopupMenu && !this.showTutorialPopupMenu && showTutorialOptions) {
        this.showTutorialPopupMenu = showTutorialOptions;
      } else {
        if (!showTutorialOptions && this.showTutorialPopupMenu && this.showPopupMenu) {
          this.showTutorialPopupMenu = false;
        } else {
          // Toggle
          this.showTutorialPopupMenu = showTutorialOptions;
          this.showPopupMenu = !this.showPopupMenu;

        }
      }
      if (this.showPopupMenu) {
        ClickAway(this, 'popupMenu', 'closePopupMenu');
      }
    },
    closePopupMenu () {
      this.showPopupMenu = false;
    },
    setCurrentRoute (toRoute: Route) {
      for (let idx in this.routes) {
        if (this.routes[idx]) {
          const route: LayoutSubRoute = this.routes[idx] as LayoutSubRoute;
          if (route.path.indexOf(toRoute.path) > -1) {
            this.currentRoute = parseInt(idx);
          }
        }
      }
    },
    async getMyInfo () {
      // Set loading to true
      this.loadingMyInfo = true;
      // Create Query
      const query: GQLTagRequestObject = gql`
        query {
          Me {
            id
            subscriptionID
            email
            profile {
              firstname
              surname
              image {
                id
                url
              }
              description
              __typename
              ... on UserProfileExtended {
                contact {
                  email
                  mobile
                }
                notify
              }
            }
            meta {
              createdOn
              lastUpdatedOn
            }
          }
        }
      `;
      // Send Request
      try {
        const response: Partial<User> = await this.$gql.Query<Partial<User>>('Me', query, {});
        this.updateVuexStore('user.id', response.id!);
        this.updateVuexStore('user.subscription', response.subscriptionID!);
        this.updateVuexStore('user.image', response.profile!.image);
        this.updateVuexStore('user.email', response.email);
        this.updateVuexStore('user.firstname', response.profile!.firstname);
        this.updateVuexStore('user.surname', response.profile!.surname);
        this.updateVuexStore('user.description', response.profile!.description);
        this.updateVuexStore('user.notify', response.profile!.notify);
        this.updateVuexStore('user.contact.email', response.profile!.contact.email);
        this.updateVuexStore('user.contact.mobile', response.profile!.contact.mobile);
        this.updateVuexStore('user.createdOn', response.meta!.createdOn);
        this.updateVuexStore('user.lastUpdatedOn', response.meta!.lastUpdatedOn);
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingMyInfo = false;
      }
    },
    updateVuexStore (key: string, value: any ) {
      this.$vuex.MutateStore(this, 'landlord', key, value);
    },
    logout () {
      // Clear session and then token and then reroute
      this.$auth0.logout(this);
    },
    showSettings () {
      const path: string = '/landlord/settings';
      this.setCurrentRoute({path} as any);
      this.$router.push(path);
      this.showPopupMenu = false;
    },
    showAcademy () {
      const path: string = '/landlord/academy';
      this.$router.push(path);
      this.showPopupMenu = false;
      this.currentRoute = -1;
    },
  },
});

// Component interfaces
interface LayoutSubRoute {
  label: string;
  path: string;
  icon: string;
  activeIcon?: string;
  activeColor?: string;
}
